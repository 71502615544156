
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'

export default defineComponent({
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const export_groups = ref([] as any)
    
    const goToStore = (id) => {
      router.push({ name: "store" })
    }

    const query = () => {
      store
        .dispatch(Actions.GET_EXPORT_GROUPS, {eppcm_id: route.params.id})
        .then(() => {
          export_groups.value = store.getters.currentExportGroups
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    query()

    const eppcm = ref({} as any)

    store
      .dispatch(Actions.GET_EPPCM, route.params.id)
      .then(() => {
        eppcm.value = store.getters.currentEppcm
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const onDownload = () => {
      store
        .dispatch(Actions.DOWNLOAD_STORE_EXPORT_GROUPS, {id: route.params.id})
        .then(() => {
          const blob = new Blob([store.getters.currentDownloadProducts], { type: "application/pdf" });
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = `pdfs_${formatDate(Date().toString())}.pdf`
          link.click();
          URL.revokeObjectURL(link.href)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = '' + d.getHours(),
            minute = '' + d.getMinutes(),
            second = '' + d.getSeconds()

        if (month.length < 2) 
            month = '0' + month
        if (day.length < 2) 
            day = '0' + day
        if (hour.length < 2) 
            hour = '0' + hour
        if (minute.length < 2) 
            minute = '0' + minute
        if (second.length < 2) 
            second = '0' + second

        return [year, month, day, hour, minute, second].join('')
    }

    return {
      goToStore,
      export_groups,
      eppcm,
      onDownload,
      ApiService
    }
  }
})
